import React from "react"
import { GetStaticProps } from "next"
import Layout from "../components/Layout"
import prisma from '../lib/prisma';

export const getStaticProps: GetStaticProps = async () => {
  return { props: { } }
}

type Props = {
}

const Homepage: React.FC<Props> = (props) => {
  return (
    <div className="page">
      <h1 style={{textAlign: 'center', marginTop: '20px'}}>HBS Staff Portal</h1>
    </div>
  )
}

export default Homepage
